import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In 2011, Marc Andreessen famously wrote that
`}<a parentName="p" {...{
        "href": "https://a16z.com/2011/08/20/why-software-is-eating-the-world/"
      }}>{`software is eating the world`}</a>{`.
His article has proven prescient and this trend shows no signs of slowing.
Unfortunately, the power to take part in the digital economy was limited to the
tiny percentage of people who could create software by hand with code. The No
Code movement is about building bridges to creators and empowering them to
create software without code. Already we see rich visual development tools for
`}<a parentName="p" {...{
        "href": "https://webflow.com"
      }}>{`creating websites & user interfaces`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://zapier.com"
      }}>{`connecting systems`}</a>{` &
`}<a parentName="p" {...{
        "href": "https://airtable.com"
      }}>{`managing data`}</a>{`. There is going to be an explosion of
specialized tools to distribute tasks that are currently confined to the work of
engineers. The component mental model will be distributed across product
organizations as the source of truth for Design Systems are moved from front-end
developers to designers using tools like `}<a parentName="p" {...{
        "href": "https://www.modulz.app/"
      }}>{`Modulz`}</a>{` &
`}<a parentName="p" {...{
        "href": "https://www.plasmic.app/"
      }}>{`Plasmic`}</a>{`. We’re going to see the data world shaken up
with tools for `}<a parentName="p" {...{
        "href": "https://luna-lang.org"
      }}>{`data processing`}</a>{` &
`}<a parentName="p" {...{
        "href": "https://teachablemachine.withgoogle.com/"
      }}>{`AI model training`}</a>{`. The cost of
creating in-house tools is going to drop with the expanding breadth of
functionality landing in
`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/blogs/aws/introducing-amazon-honeycode-build-web-mobile-apps-without-writing-code/"
      }}>{`cloud providers`}</a>{`
& `}<a parentName="p" {...{
        "href": "https://retool.com"
      }}>{`new visual tools`}</a>{`. It’s only a matter of time before
accessible general purpose visual development unleashes the masses. Yes, Marc
Andreessen was right, software is eating the world! Today more people can create
software than ever and this trend will only continue as No Code eats software.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Originally authored & published for a `}<a parentName="p" {...{
          "href": "https://ui.dev/"
        }}>{`UI.dev`}</a>{` newsletter.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      